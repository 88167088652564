*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --color: #994d97;
  --color-secondary: #dfd5e8;
  --color-tertiary: #461f54;
  --color-text: #fff;
  --color-text-secondary: #000;
  --color-text-tertiary: #994d97;
  --color-overlay: rgba(255,255,255,0.4);
  --font-family: 'Raleway', sans-serif;
  --font-size-header: 18pt;
  --font-size-sub-header: 14pt;
  --font-size-text-large: 12pt;
  --font-size-text: 10pt;
  --hover-brightness: 1.2;
  --justify-important: center;
  --justify-normal: left;
  --line-height: 1.5;
}

#update-banner {
  position: fixed;
  bottom: calc(0% - 108px);
  left: calc(50% - 235px);
  width: 470px;
  height: 54px;
  transition: bottom .5s linear;
  text-align: center;
  padding: 10px 20px;
  margin: 0px;
  font-family: var(--font-family);
  background-color: var(--color-secondary);
  color: var(--color-tertiary);
  border-radius: 5px 5px 0px 0px;
}

#update-banner button {
  margin-left: 10px;
}

.examine-main-background {
  margin: 0px;
  width: 100vw;
  height: 100vh;
  background-color: var(--color);
  background: linear-gradient(45deg, var(--color) 35%, var(--color-tertiary) 35% 80%, var(--color-secondary) 80% 100%);
  color: var(--color-text);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h3 { 
  font-family: var(--font-family);
  font-size: var(--font-size-header);
  font-weight: bold;
  color: var(--color-text);
  margin: 0px;
}

h5 {
  font-family: var(--font-family);
  font-size: var(--font-size-sub-header);
  font-weight: bold;
}

p,  li {
  font-family: var(--font-family);
}

span {
  font-family: var(--font-family);
}

input {
  font-family: var(--font-family);
}

button {
  font-family: var(--font-family);
  font-weight: bold;
}

hr {
  border: 1px solid var(--color-text-secondary) 
}

button {
  color: var(--color-text);
  background-color: var(--color);
  padding: 5px 15px 5px 15px;
  border: none;
  font-size: var(--font-size-text-large);
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-tertiary);
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

#download-ignored {
  position: fixed;
  bottom: 0;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  /* width: 470px;
  height: 54px; */
  text-align: center;
  padding: 10px 20px;
  margin: 0px;
  font-family: var(--font-family);
  background-color: var(--color-secondary);
  color: var(--color-tertiary);
  border-radius: 5px 5px 0px 0px;
}

#download-ignored p {
  margin-bottom: 5px;
  color: black;
}

#download-ignored p:last-of-type {
  margin-bottom: 10px;
}

.close-modal {
  text-align: right !important;
}

.close-modal svg {
  font-size: 16pt;
  cursor: pointer;
}

.close-modal svg path {
  stroke: var(--color-text-tertiary);
}

.main-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: width .2s ease-in;
}

.main-icon {
  display: inline-block;
  position: relative;
  top: 50px;
  background-color: var(--color);
  padding: 15px;
}

.main-icon-image {
  margin: 0px;
  font-size: 52pt;
}

.main-wrapper {
  padding: 20px 50px 20px 50px;
  color: var(--color-text-secondary);
  background-color: var(--color-secondary);
  transition: all .2s ease-in;
}

.login-main-text, .home-main-text, .finish-main-text {
  margin: 40px 0px 15px 0px;
}

.login-main-text h5, .home-main-text h5, .finish-main-text h5 {
  margin-bottom: 15px;
}

.home-main-text hr, .finish-main-text hr {
  margin: 8px 0px;
}

.home-main-exams h5 {
  margin-top: 30px;
}

.login-main-text p, .home-main-text p, .finish-main-text p {
  margin-bottom: 15px;
}

.finish-main-text-icon-container {
  background-color: var(--color);
  width: 65px;
  height: 65px;
  margin: 20px auto;
  padding: 10px;
  text-align: center;
  border-radius: 100%;
}

.finish-main-text-icon {
  font-size: 34pt;
  vertical-align: middle;
  color: var(--color-text);
}

.help-text {
  margin-bottom: 5px;
}

.help-text-email {
  color: var(--color-text-tertiary);
}

.login-main-body {
  margin: 0px auto;
  padding: 5px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.login-main-input, .login-main-input-invalid {
  margin: 0px auto 15px;
  padding: 5px;
  padding-right: 60px;
  width: 100%;
  font-size: 30pt;
  font-family: var(--font-family);
  text-align: center;
  border: none;
  overflow: hidden;
}

.login-main-input-container {
  display: flex;
  margin: auto;
  width: 80%;
  
}

.login-main-toggle {
  height: 100%;
  font-size: 30pt;
  margin-left: -60px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  /* background-color: var(--color-text); */
}

.login-main-toggle-icon {
  margin: auto;
  color: var(--color-text-tertiary);
  /* background-color: var(--color-text); */
}

.login-main-toggle-button {
  font-size: 40pt;
  display: flex;
  height: 70px;
  width: 60px;
  background: none;
}

.login-main-toggle-button:focus {
  border: none
}

.login-main-input:focus {
  outline: 0;
  box-shadow: 0 0 0 3px var(--color-tertiary);
  font-size: 30pt;
}

.login-main-input-invalid, .login-main-input-invalid:focus {
  outline: 0;
  box-shadow: 0 0 0 3px red;
}

.main-wrapper button {
  color: var(--color-text);
  background-color: var(--color);
  padding: 5px 15px 5px 15px;
  border: none;
  font-size: var(--font-size-text-large);
}

.main-wrapper button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-tertiary);
}

.login-main-toggle button:focus {
  box-shadow: none
}

.main-logo{
  margin-top: 20px
}

.main-logo img{
  width: 300px
}

.home-main-exams {
  margin-bottom: 30px;
  text-align: left; 
}

.home-main-exams ul {
  padding-left: 30px;
}

.home-main-exams ul li {
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  font-weight: bold;
}

.home-main-exam-section-status-NEXT {
  color: var(--color);
  font-size: 16px!important;
}

.home-main-exam-section-status-COMPLETED {
  color: green;
}

.icon-button {
  line-height: 1px;
}

.exam-main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 100px;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.exam-main-top-action, .exam-main-bottom-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  min-width: 620px;
  background-color: var(--color-tertiary);
}

.exam-main-top-action button {
  padding: 3px 8px;
}

.exam-main-exam-title, .exam-main-bottom-save {
  justify-self: start;
  padding: 10px 1% 10px 5%;
  font-size: var(--font-size-text-large);
}

.exam-main-exam-title {
  font-size: var(--font-size-text-large);
}

.exam-main-exam-title h3 {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.exam-main-time-remaining, .exam-main-bottom-question {
  display: grid;
  justify-self: center;
  padding: 10px 1%;
  text-align: center;
  white-space: nowrap;
}

.exam-main-time-remaining {
  grid-column-gap: 10px;
}

.exam-main-time-remaining *, .exam-main-bottom-question * {
  height: 100%;
  grid-row: 1;
}

.exam-main-time-remaining h5, .exam-main-bottom-question h5 {
  display: inline-block;
  color: var(--color-text);
}

.exam-main-time-remaining h5 {
  margin: 0px;
  font-size: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
}

.exam-main-bottom-question h5 {
  margin: 0px 10px;
  width: 180px;
}

.exam-main-time-remaining-pause {
  padding: 3px 5px!important;
}

.exam-main-time-remaining-pause-icon {
  vertical-align: middle;
}

.exam-main-logo-help, .exam-main-bottom-finish {
  justify-self: end;
  padding: 10px 5% 10px 1%;
  white-space: nowrap;
}

.exam-main-logo-help {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 20px;
  align-items: center;
}

.exam-main-logo-help-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 10px;
  width: 100px;
  margin-left: 25px;
}

.exam-main-logo-help-buttons button {
  box-sizing: border-box;
  display: inline-block;
  width: 80%;
  height: 100%;
  margin: auto;
  padding: 5px;
  font-size: var(--font-size--large-text);
  text-align: center;
}

.exam-main-logo-help-buttons svg {
  height: 100%;
}

.exam-main-logo-help-button-dictionary svg {
  font-size: 31px;
}

.exam-main-logo-help-button-settings svg {
  font-size: 20px;
}

.exam-main-logo-help img {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  vertical-align: middle;
}

.exam-main-bottom-action {
  background-color: var(--color-tertiary);
}

.exam-main-questions-container {
  border-bottom: 2px solid var(--color);
  overflow: hidden;
  width: 100vw;
}

.exam-main-questions {
  margin: 0px;
  white-space: nowrap;
  transition: transform 400ms ease-in-out 0s;
  padding: 0px;
  /* display: grid;
  align-items: center; */
  display: flex;
  align-items: center;
  height: 42px;
}

.exam-main-questions-question {
  display: inline-block;
  box-sizing: content-box;
  width: 30px;
  min-width: 30px;
  height: 20px;
  background-color: var(--color-secondary);
  text-align: center;
  margin: 2px;
  padding: 2px;
  font-family: var(--font-family);
  font-size: var(--font-size-text-large);
  line-height: initial;
  cursor: pointer;
  transition: all 0.2s linear;
  vertical-align: middle;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  grid-row: 1;
}

.exam-main-questions-active {
  transform: scale(1.5);
  margin: 8.5px calc((-40px * (1 - 1.5)) / 2) calc(-20px * (1 - 1.5));
}

.exam-main-questions-question-completed {
  background-color: #00C91E;
}

.exam-main-questions-question-skipped {
  background-color: #FF9E00;
}

.exam-main-body {
  display: grid;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--color-overlay);
}

.exam-main-body h3 {
  color: var(--color-text-secondary);
}

.exam-main-body h5 {
  font-weight: 100;
  font-size: 120%;
}

.exam-main-body-font-size-small {
  font-size: 90%!important;
}

.exam-main-body-font-size-medium {
  font-size: 120%!important;
}

.exam-main-body-font-size-large {
  font-size: 150%!important;
}

.exam-main-body-high-contrast {
  background-color: black!important;
  color: white!important
}

.exam-main-body-question-panel {
  text-align: left;
  padding: 20px;
  font-size: 120%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.exam-main-body-question-panel hr {
  width: 50%;
  margin: 50px auto;
  border: 1px solid var(--color-text-tertiary)
}

.exam-main-body-question-text {
  margin-bottom: 20px;
}

.exam-main-body-question-text p {
  margin-bottom: 0px;
}

.exam-main-body-question-text img {
  max-width: 100%;

}

.exam-main-body-question-answers {
  /* display: grid;
  grid-template-columns: 10% 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px; */
}

.md-checkbox {
  position: relative;
  margin: 1em 40px 0 0;
  text-align: left;
}
.md-checkbox.md-checkbox-inline {
  display: inline-block;
}
.md-checkbox.md-checkbox-error label:before {
  border: 2px solid red;
}
.md-checkbox label {
  cursor: pointer;
  display: inline;
  line-height: 1.75em;
  vertical-align: top;
  clear: both;
  padding-left: 1px;
  /* font-size: 12pt; */
}
.md-checkbox label:not(:empty) {
  padding-left: 0.75em;
}
.md-checkbox label:before, .md-checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.md-checkbox label:before {
  width: 1.75em;
  height: 1.75em;
  background: #fff;
  border: 2px solid var(--color-tertiary);
  border-radius: 0.125em;
  cursor: pointer;
  transition: background 0.3s;
}
.md-checkbox input[type="checkbox"] {
  outline: 0;
  visibility: hidden;
  width: 1.75em;
  margin: 0;
  display: block;
  float: left;
  font-size: inherit;
}
.md-checkbox input[type="checkbox"]:checked + label:before {
  background:#461f54;
  border: none;
}
.md-checkbox input[type="checkbox"]:checked + label:after {
  transform: translate(0.25em, 0.3942307692em) rotate(-45deg);
  width: 1.25em;
  height: 0.625em;
  border: 0.25em solid #fff;
  border-top-style: none;
  border-right-style: none;
}
.md-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.md-checkbox input[type="checkbox"]:disabled:checked + label:before {
  background: rgba(0, 0, 0, 0.26);
}

.exam-main-body-question-answers-list-identifier {
  color: var(--color-text-tertiary);
  margin-right: 10px;
}

.exam-main-body-slider {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 30px 1fr;
  cursor: col-resize;
}

.exam-main-body-slider-icon{
  box-sizing: border-box;
  display: inline-block;
  width: 60%;
  height: 100%;
  margin: auto;
  font-size: var(--font-size-header);
  text-align: center;
  background-color: var(--color);
  color: var(--color-text);
}

.exam-main-body-slider-icon svg {
  height: 100%;
  vertical-align: unset;
}

.exam-main-body-slider-separator {
  background-color: #aaa;
  width: 5px;
  height: 100%;
  margin: auto;
}

.exam-main-body-resource-panel {
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.exam-main-body-resource-panel * {
  max-width: 100%;
}

.exam-main-body-resource-panel hr {
  width: 50%;
  margin: 20px auto;
  border: 2px solid var(--color-tertiary);
}

.exam-main-pause-splash, .exam-main-help-splash, .exam-main-finish-modal-splash,
.exam-main-settings-splash, .exam-main-dictionary-splash, .exam-main-save-confirm-splash {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--color);
}

 /* .exam-main-help-splash {
  opacity: 0.9;
 } */

 .exam-main-help-splash, .exam-main-settings-splash, .exam-main-dictionary-splash,
 .exam-main-save-confirm-splash {
  background-color: unset;
}

.exam-main-pause-splash-container, .exam-main-help-splash-container, .exam-main-finish-modal-splash-container,
.exam-main-settings-splash-container, .exam-main-dictionary-splash-container, .exam-main-save-confirm-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--color-text);
  font-size: 200%;
  z-index: 5;
}

.exam-main-help-splash-container {
  display: grid;
  grid-template-rows: 70px 1fr 50px;
  width: 80vw;
  height: 70vh;
  padding: 10px;
  border: 3px solid var(--color);
  background-color: var(--color-tertiary);
  overflow-y: auto;
}

.exam-main-help-splash-container h3 {
  /* margin-bottom: 20px; */
}

.exam-main-help-splash-container p {
  font-size: 70%;
  margin: 30px;
}

.exam-main-help-splash-container img {
  max-width: 80%;
  /* border: 2px solid var(--color-secondary); */
  margin-bottom: 20px;
}

.exam-main-help-splash-container-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 2px solid var(--color);
  padding-bottom: 10px;
}

.exam-main-help-splash-container-top h3 {
  margin: 0px 20px;
}

.exam-main-help-splash-container-body {
  width: 100%;
  overflow-y: auto;
  padding: 20px 0px;
}

.exam-main-help-splash-container-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 2px solid var(--color);
  padding-top: 10px;
}

.exam-main-pause-splash-container p {
  font-size: var(--font-size-header);
}

.exam-main-finish-modal-splash {
  z-index: 5
}

.exam-main-finish-modal-splash-container h3 {
  margin-bottom: 20px;
}

.exam-main-finish-modal-splash-container p {
  font-size: var(--font-size-sub-header);
  margin-bottom: 40px;
}

.exam-main-finish-modal-splash-container-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  justify-content: center;
  align-content: center;
}

#exam-main-finish-modal-splash-container-finish {
  background-color: var(--color-tertiary);
}

#exam-main-finish-modal-splash-container-cancel {
  background-color: red;
}

.exam-main-settings-splash-container {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  justify-items: center;
  width: 500px;
  height: 400px;
  padding: 10px;
  border: 3px solid var(--color);
  background-color: var(--color-tertiary);
}

.exam-main-settings-splash-container h3 {
  grid-column: 1 / span 2;
}

.exam-main-settings-splash-container p {
  font-size: 50%;
  margin-bottom: 0px;
}

.exam-main-settings-splash-container-buttons {
  display: grid;
  grid-column-gap: 5px;
}

.exam-main-settings-splash-container-buttons button {
  grid-row: 1;
}

.exam-main-dictionary-splash-container {
  width: 80vw;
  height: 80vh;
  border: 3px solid var(--color);
  display: grid;
  grid-template-rows: 1fr 40px;
  background-color: var(--color);
}

.exam-main-dictionary-iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.exam-main-save-confirm-container {
  background-color: var(--color-tertiary);
  border: 3px solid var(--color);
  padding: 20px;
}

.exam-main-save-confirm-container h5 {
  margin-bottom: 20px;
}

.exam-main-save-confirm-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.exam-main-save-confirm-buttons button {
  width: 140px;
}

.setting-selected {
  background-color: var(--color-tertiary);
  border: 2px solid var(--color);
}

.download-progress-container {
  margin: 20px 0px;
  font-family: var(--font-family);
}

.download-progress-background {
  margin: auto;
  border: 2px solid black;
  border-radius: 15px;
  width: 80%;
  height: 30px;
}

.download-progress-foreground {
  background-color: var(--color);
  height: 26px;
  border-radius: 13px;
  transition: width .2s ease-in;
}

.freetext-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.freetext-reset {
  white-space: nowrap;
  padding: 3px 10px 3px 7px;
}

.freetext-reset svg {
  font-size: 28px;
}

.freetext-reset span {
  position: relative;
  top: 2px;
}

.freetext-word-count {
  width: 100%;
  font: var(--ck-font-face);
  font-size: 12pt;
  color: var(--ck-color-text);
  text-align: right;
  padding: 2px 5px;
}

.freetext-word-count p{
  margin-bottom: 0px;
}

.ck-editor {
  font-size: 80%;
}

.ck-editor p {
  margin-bottom: 5px;
}

.ck-editor ul, .ck-editor ol {
  margin: 10px 20px;
}

.ck-button {
  color: #000;
}

.ck-content {
  height: 140px;
}

.classification-container {
  width: 100%;
  display: grid;
  border: 2px solid var(--color);
}

.classification-body, .classification-columns {
  display: grid;
  justify-items: center;
  align-content: center;
  border-bottom: 2px solid var(--color);
}

.classification-columns {
  background-color: var(--color-tertiary);
}

.classification-rows {
  display: grid;
  grid-template-columns: 30% 70%;
}

/* .classification-column {
  color: var(--color-text);
  width: 100%;
  text-align: center;
} */

.classification-column {
  display:flex;
  justify-content:center;
  align-items:center;
  border-right: 2px solid var(--color);
  border-bottom: 2px solid var(--color);
  background-color: var(--color-tertiary);
  text-align: center;
  color: var(--color-text);
  padding: 5px;
}

.classification-drop-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 2px solid var(--color);
  min-height: 74px;
  width: 100%;
}

 /* .classification-drop-area:not(:last-child), .classification-column:not(:last-child) {
  border-bottom: 2px solid var(--color)
}  */
/*
.classification-drop-area {
  width: 100%;
  min-height: 150px;
  text-align: center;
} */

.classification-options, .classification-drop-area {
  margin: 0px;
}

.classification-options {
  min-height: 100px;
  background-color: var(--color-tertiary);
  padding: 10px;
}

.drag-to-complete-options {
  min-height: 100px;
  background-color: var(--color-tertiary);
  padding: 10px; 
}

.classification-option, .classification-drop-area-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.classification-option-inner {
  /* width: 50%; */
  padding: 10px;
  background-color: var(--color);
  color: var(--color-text);
  text-align: center;
  border-radius: 4px;
  margin: 5px 0px;
}

.classification-options-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.drag-to-complete-table-option {
  display: block;
  width: 90%;
  height: 60px;
  text-align: center;
  background-color: var(--color);
  color: var(--color-text);
  border-radius: 4px;
  margin: 10px 5%;
}

.drag-to-complete-table-option-text {
  position: relative;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0px;
}

.classification-option-area-option {
  width: 50%;
  margin: 10px auto;
}

.classification-option {
  padding: 2px 5px;
}

.canvas-container {
  margin: auto
}

.inline_dropdown_container {
  line-height: 2;
}

.inline_dropdown_container span select {
  padding: 5px;
  background-color: var(--color);
  border: 1px solid var(--color-tertiary);
  color: white;
  max-width: 100%;
}

.ab_list_container {
  display: grid;
  background-color: var(--color);
  border: 1px solid var(--color-tertiary);
  color: white;
}

.ab_list_cell {
  text-align: center;
  border: 0.5px solid var(--color-tertiary);
  padding: 5px;
}

.ab_list_checkbox {
  margin: auto;
  left: calc(50% - 20px);
}

.image_labelling_answer_grid {
  display: grid;
  grid-template-columns: 25px minmax(auto, 500px);
  grid-gap: 10px;
}

.image_labelling_answer_label {
  justify-self: start;
  align-self: center;
}

.image_labelling_answer_grid select {
  padding: 5px;
  background-color: var(--color);
  border: 1px solid var(--color-tertiary);
  color: white;
}

.dnd-copy {
  transform: none !important;
}

.drag_to_complete_table {
  width: 100%;
}

.drag_to_complete_table tr:first-child td {
  font-weight: bold;
  /* white-space: nowrap; */
}

.drag_to_complete_table td {
  border: 1px solid var(--color-tertiary);
  text-align: center;
  /* padding: 3px; */
  height:100%;
  position: relative;
}

.drag_to_complete_table td select {
  border: none;
  width: 100%;
  height: 100%;
  background-color: var(--color);
  color: white;
  position: absolute;
  left: 0;
  top: 0;
}

.ck-editor__editable_inline {
  min-height: 400px;
}